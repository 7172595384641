/* DASHBOARD STYLES STARTS HERE */
.wrapper {
  padding: 100px 50px;
  text-align: center;
}

.table{
  --bs-table-bg: transparent !important;
}

.oja-ellipsis {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cards_grid {
  display: grid;
  /* To distribute cards in the grid without fixing the number of cols, 
    use auto-fit, eg grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  */
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: auto;
  grid-auto-flow: row dense;
  box-shadow: 0px 1px 3px grey;
  padding: 20px;
}

.card_single {
  border: 1px solid black;
  border-radius: 5px;
  transform-origin: 0 0;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_single.draggable {
  border-color: transparent;
  box-shadow: 0px 1px 3px darkgrey;
}

.empty-state .empty-state-btn {
  width: 20%;
  margin: auto;
}

.empty-state .empty-state-btn button {
  background-color: #6e449b;
}

.google-analytics {
  text-decoration: none;
  padding: 7px 32px;
  border: none;
  background: #4285f4 !important;
  color: #ffffff;
  border-radius: 8px;
}
.google-analytics:hover {
  color: #ffffff;
}

/* Pagination starts */
.active > .page-link {
  background-color: #6e449b;
  border-color: #6e449b;
}

.page-link {
  color: #6e449b;
}

.pagination-right {
  display: flex;
  flex-direction: row-reverse;
}
/* Pagination ends */

/* dropdown starts */
.oja-dropdown {
  position: relative;
  display: inline-block;
}
.oja-dropdown img {
  cursor: pointer;
}
.oja-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}
.oja-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.oja-dropdown-content a:hover {
  background-color: #6e449b;
  color: #ffffff;
}
.oja-dropdown:hover .oja-dropdown-content {
  display: block;
}
/* dropdown ends */

.oja-dashboard .oja-dashboard-sidebar {
  padding-top: 72px;
  width: 265px;
  height: 100vh;
  position: fixed;
  overflow: auto;
  border-right: 2px solid rgba(110, 68, 155, 0.3);
}

.oja-dashboard .oja-dashboard-navbar {
  position: fixed;
  width: 100%;
  z-index: 800;
  background: #fbf8ff;
}

.oja-dashboard .oja-dashboard-content {
  padding-top: 100px !important;
  margin-left: 266px;
  padding: 2rem;
  background: #ffffff;
  height: 100vh;

  position: relative;
}

.oja-dashboard .heading h1 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #6e449b;
}

.oja-dashboard-header .heading {
  display: flex;
  justify-content: space-between;
}

.oja-dashboard-header .heading button {
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 5px;
  background: white;
  height: 34px;
  width: 200px;
}

/* DASHBOARD STYLES STOPS HERE */

.oja-order .nav-tabs {
  all: unset;
  display: flex !important;
  list-style-type: none;
}

.oja-order .nav-tabs .nav-link.active {
  background-color: #6e449b;
  color: #ffffff;
}

.oja-order .nav-tabs .nav-link {
  color: #000000;
}

/* DASHBOARD SIDEBAR STYLES STARTS HERE */

.sidebar-content .sidebar-item.plain img {
  display: none;
}

.oja-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.oja-sidebar-container {
  background-color: #fff;
  padding-left: 20px;
  padding-right: 25px;
  overflow: auto;
}

.oja-sidebar-logo {
  margin-top: 13px;
  margin-bottom: 50px;
}

/* DASHBOARD SIDEBAR STYLES STOPS HERE */

/* DASHBOARD SIDEBAR ITEM STYLES STARTS HERE */

.sidebar-item {
  margin-top: 10px;
  padding: 0.75em 1em;
  display: block;
  transition: background-color 0.15s;
  border-radius: 4px;
}
.sidebar-item:hover {
  background: #6e449b;
  color: #fff !important;
}

.sidebar-title {
  display: flex;
  font-family: "Clash Display";
  font-size: 16px;
  line-height: 22px;
  color: #b6b6b6;
  /* justify-content: space-between; */
}
.sidebar-title:hover {
  color: white;
}
.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
  margin-left: auto;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}
.sidebar-content {
  padding-top: 0.2em;
  padding-left: 30px;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open ~ .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  font-family: "Clash Display";
  font-size: 16px;
  line-height: 22px;
  color: #b6b6b6;
  text-decoration: none;
}
.sidebar-item.plain:hover {
  text-decoration: underline;
}

.sidebar-item img {
  margin-right: 10px;
}

.sidebar-item.logout {
  margin-top: 27em;
}

/* DASHBOARD SIDEBAR ITEM STYLES STOPS HERE */

/* DASHBOARD NAVBAR STYLES STARTS HERE */

.oja-nav-dashboard {
  padding: 15px;
  border-bottom: 2px solid rgba(62, 14, 84, 0.25);
}

.oja-nav-dashboard .oja-nav-logo {
  margin-left: 10px;
}

.oja-nav-dashboard .oja-nav-form {
  margin-left: -80px;
}

.oja-nav-dashboard .form-control {
  border: 1px solid #e5e5e5;
  background: #f8f8f8;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 425px;
  height: 40px;
}

.oja-nav-dashboard .form-control:focus {
  background: #f8f8f8;
  border-radius: 4px;
}

.oja-nav-dashboard .oja-img-item {
  margin-left: auto;
}

.oja-nav-dashboard .oja-img-item > button {
  all: unset;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
}
.oja-nav-dashboard .oja-img-item > button::before {
  content: attr(data-count);
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: red;
  color: white;
}

.oja-nav-dashboard .oja-notification {
  position: absolute;
  right: 30px;
}
.oja-nav-dashboard .oja-notification .card {
  width: 400px;
}
.oja-nav-dashboard .oja-notification .card .clear {
  margin-top: auto;
  margin-bottom: auto;
  color: red;
  border: none;
  background: none;
}
/* .oja-notification-img {
  margin-left: 20px;
  position: relative;
} */
.oja-notification-img::before {
  content: "5";
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: red;
  color: white;
}

/* DASHBOARD NAVBAR STYLES STOPS HERE */

/* DASHBOARD HOME STYLES STARTS HERE */

.oja-dashboard-home .card {
  border: none;
  border-radius: 4px;
  background: #f4ebf9;
}

@property --p {
  syntax: "<number>";
  inherits: true;
  initial-value: 1;
}

.pie {
  --p: 100;
  --b: 20px;
  --c: darkred;
  --w: 150px;

  width: var(--w);
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
}
.pie:before,
.pie:after {
  content: "";
  position: absolute;
  border-radius: 50%;
}
.pie:before {
  inset: 0;
  background: radial-gradient(farthest-side, var(--c) 98%, #0000) top/var(--b)
      var(--b) no-repeat,
    conic-gradient(var(--c) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}
.pie:after {
  inset: calc(50% - var(--b) / 2);
  background: var(--c);
  transform: rotate(calc(var(--p) * 3.6deg))
    translateY(calc(50% - var(--w) / 2));
}
.animate {
  animation: p 1s 0.5s both;
}
.no-round:before {
  background-size: 0 0, auto;
}
.no-round:after {
  content: none;
}
@keyframes p {
  from {
    --p: 0;
  }
}

/* DASHBOARD HOME STYLES STOPS HERE */

/* DASHBOARD CUSTOMER STYLES STARTS HERE */

.oja-customer-dashboard .heading .form-select {
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 5px;
  height: 34px;
}

.oja-customer-dashboard .heading .form-select:focus {
  background-color: #6e449b;
  color: #fff;
}

.oja-customer-dashboard .heading button {
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 5px;
  background: white;
  height: 34px;
  width: 193px;
}

.oja-customer-dashboard .heading button,
.form-select {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8b8c8c;
}

/* DASHBOARD CUSTOMER STYLES STOPS HERE */

/* DASHBOARD ADD CUSTOMER STYLES STARTS HERE */

.oja-add-customer-dashboard .profile-items {
  height: auto;
  background: #f7e7ff;
  margin-top: 26px;
  margin-left: -33px;
  margin-right: -33px;
}

.oja-add-customer-dashboard .profile-img {
  width: 150.57px;
  height: 142.35px;
  margin-top: 25px;
  margin-left: 22.91px;
  cursor: pointer;
  object-fit: cover !important;
}

.oja-add-customer-dashboard .profile-items .info {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: rgba(110, 68, 155, 0.3);
  margin-left: 44.01px;
  margin-top: 3.39px;
}

.oja-add-customer-dashboard .profile-items-input .form {
  margin-bottom: 0 !important;
}

.oja-add-customer-dashboard .profile-items-input .error {
  color: red;
  font-size: 15px;
}

.oja-add-customer-dashboard .profile-items-input .formik-input {
  background: transparent;
  width: 453px;
  height: 48px;
  border: 1px solid #6e449b;
  border-radius: 8px;
}

.oja-add-customer-dashboard .profile-details a {
  color: #000 !important;
}

.oja-add-customer-dashboard .profile-details .card {
  max-width: 725px;
  border: 3px solid rgba(110, 68, 155, 0.3);
  border-radius: 8px;
}

.oja-add-customer-dashboard .profile-details .card .cus-details p {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6e449b;
}

.oja-add-customer-dashboard .card .profile-items-input .formik-input {
  max-width: 453px;
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 8px;
  height: 44px;
}

.oja-add-customer-dashboard
  .card
  .profile-items-input
  .formik-input::placeholder {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(110, 68, 155, 0.3);
}

.oja-add-customer-dashboard .profile-details .profile-btn {
  max-width: 725px;
}

.oja-add-customer-dashboard .profile-details .edit {
  padding: 7px 32px;
  border: none;
  background: #ffde17;
  border-radius: 8px;
}

.oja-add-customer-dashboard .profile-details button {
  width: 101px !important;
  color: #000;
}

.oja-add-customer-dashboard .profile-details a .edit {
  padding: 7px 32px;
  border: none;
  background: #ffde17;
  border-radius: 8px;
}

/* DASHBOARD ADD CUSTOMER STYLES STOPS HERE */

/* DASHBOARD VIEW CUSTOMER STYLES STARTS HERE */

.oja-add-customer-dashboard .profile-items .view-profile-img {
  width: 150.57px;
  height: 142.35px;
  margin-left: 30.01px;
  margin-top: 15px;
}

.oja-add-customer-dashboard .profile-items .view-profile-items-det {
  margin-left: 57px;
  margin-top: 56.41px;
}

.oja-add-customer-dashboard .profile-items .view-profile-items-det h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #6e449b;
  letter-spacing: -0.02em;
}

.oja-add-customer-dashboard .profile-items .view-profile-items-det p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6e449b;
}

.oja-add-customer-dashboard .profile-details .view-profile-btn {
  max-width: 725px;
}

.oja-add-customer-dashboard .table-title {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 24px;
  color: #6e449b;
}

/* DASHBOARD VIEW CUSTOMER STYLES STOPS HERE */

/* DASHBOARD CUSTOMER REP STYLES STARTS HERE */
.oja-customer-rep-dashboard .card {
  border: 1.5px solid rgba(110, 68, 155, 0.3);
  border-radius: 14px;
}

.oja-customer-rep-dashboard .rep-details {
  margin-left: 37.98px;
}

.oja-customer-rep-dashboard .rep-details h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #6e449b;
}

.oja-customer-rep-dashboard .rep-details p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #6e449b;
}

.oja-customer-rep-dashboard .loading {
  margin-top: 25%;
}

.dropdown-toggle {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  background: none !important;
}

.dropdown-toggle:focus {
  background-color: transparent;
  box-shadow: none !important;
}

.dropdown-menu {
  border: 1px solid rgba(110, 68, 155, 0.3);
  box-shadow: -4px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0;
  inset: 0px auto auto -88px !important;
}

.dropdown-item:hover:nth-child(1) {
  background: #6e449b;
  border-radius: 8px 8px 0px 0px;
  color: #ffffff;
}

.dropdown-item:hover:nth-child(2) {
  background: #6e449b;
  border-radius: 0px 0px 8px 8px;
  color: #ffffff;
}

/* DASHBOARD CUSTOMER REP STYLES STOPS HERE */

/* DASHBOARD ADD CUSTOMER REP STYLES STARTS HERE */

.oja-add-customer-rep-dashboard a {
  color: #000;
}

.oja-add-customer-rep-dashboard .card {
  max-width: 677px;
  border: 1.5px solid #6e449b;
  border-radius: 14px;
}

.oja-add-customer-rep-dashboard .card .profile-img {
  width: 150.57px;
  height: 142.35px;
  object-fit: cover;
  cursor: pointer;
}

.oja-add-customer-rep-dashboard .card .con {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: rgba(110, 68, 155, 0.3);
  margin-top: 3.39px;
}

.oja-add-customer-rep-dashboard .form {
  margin-bottom: 0 !important;
}

.oja-add-customer-rep-dashboard .formik-input {
  width: 453px !important;
  height: 54px;
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 8px;
  font-size: 16px;
}

.oja-add-customer-rep-dashboard .formik-input::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(110, 68, 155, 0.3) !important;
}

.oja-add-customer-rep-dashboard .basic-multi-select .select__control {
  width: 453px !important;
  border-radius: 8px !important;
  /* height: 54px !important; */
}

.oja-add-customer-rep-dashboard .add-rep-btn {
  max-width: 680px;
  text-align: right;
}

.oja-add-customer-rep-dashboard .add-rep-btn button {
  width: 103px !important;
  padding: 7px 32px;
  border: none;
  background: #ffde17 !important;
  border-radius: 8px;
}

/* DASHBOARD ADD CUSTOMER REP STYLES STOPS HERE */

/* DASHBOARD ADD RIDER STYLES STARTS HERE */

.oja-add-rider-dashboard a {
  margin-bottom: 36px;
}

.oja-add-rider-dashboard a p {
  color: #000;
}

.oja-add-rider-dashboard .card {
  max-width: 621px;
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 8px;
}

.oja-add-rider-dashboard .profile-img {
  cursor: pointer;
}

.oja-add-rider-dashboard .card .form-group {
  text-align: -webkit-center;
}

.oja-add-rider-dashboard .card .form-group .info {
  color: #6e449b;
}

.text-area {
  width: 100%;
  /* height: 44px; */
  border: 1px solid #6e449b;
  border-radius: 8px !important;
  padding: 0.375rem 0.75rem;
}

.oja-add-rider-dashboard .card .form-control,
.formik-input {
  width: 100%;
  height: 44px;
  border: 1px solid #6e449b;
  border-radius: 8px !important;
  padding: 0.375rem 0.75rem;
}

.oja-add-rider-dashboard .card .select__control {
  width: 453px;
  height: 44px;
  border: 1px solid #6e449b !important;
  border-radius: 8px !important;
}

.oja-add-rider-dashboard .card .form-select {
  max-width: 78%;
  height: 44px;
  border: 1px solid #6e449b;
  border-radius: 8px;
  color: #6e449b;
}

.oja-add-rider-dashboard .card .form-select .selected {
  color: #8b8c8c !important;
}

.oja-add-rider-dashboard .card .form-select:focus {
  background-color: #6e449b !important;
  color: #fff;
}

.oja-add-rider-dashboard .card .icon {
  padding-left: 30px;
  /* background: url(/svgs/input-profile.svg) no-repeat left; */
  background-position-x: 4px;
  background-size: 20px;
}
.oja-add-rider-dashboard .card .icon.email {
  padding-left: 30px;
  /* background: url(/svgs/input-message.svg) no-repeat left; */
  background-position-x: 4px;
  background-size: 20px;
}
.oja-add-rider-dashboard .card .icon.phone {
  padding-left: 30px;
  /* background: url(/svgs/input-call.svg) no-repeat left; */
  background-position-x: 4px;
  background-size: 20px;
}

.oja-add-rider-dashboard .card .add-rider-btn {
  max-width: 521px;
  text-align: right;
}

.oja-add-rider-dashboard .card .add-rider-btn button {
  background: #ffde17;
  border-radius: 8px;
  border: none;
  width: 103px !important;
  height: 38px;
  margin-right: auto;
}

/* DASHBOARD ADD RIDER STYLES STOPS HERE */
/* DASHBOARD RIDER DETAILS STYLES STARTS HERE */
.oja-rider-details-dashboard .card {
  width: 767px;
  border: 1px solid #6e449b;
  border-radius: 10px;
}
.oja-rider-details-dashboard .card-body {
  text-align: -webkit-center;
}
.oja-rider-details-dashboard .view-input {
  border: 1px solid #6e449b;
  border-radius: 8px;
  width: 100%;
  height: 48px;
}
.oja-rider-details-dashboard .view-input > div {
  margin: 12px;
}

/* DASHBOARD RIDER DETAILS STYLES STOPS HERE */

/* DASHBOARD ORDER STYLES STARTS HERE */

.oja-order-dashboard .card {
  background: #fbf8ff;
  border: 1px solid #6e449b;
  border-radius: 8px;
}

.oja-order-dashboard .card .order-img {
  width: 119.17px;
  height: 133.84px;
}

.oja-order-dashboard .card .order-details {
  margin-left: 47px;
}

.oja-order-dashboard .card .order-details p {
  margin-bottom: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #231f20;
}

.oja-order-dashboard .card .order-btn {
  border: 2px solid #6e449b;
  border-radius: 8px;
  width: 113px !important;
  margin-top: 27px;
  padding: 7px 25px;
  background-color: transparent;
  color: #6e449b;
}

.oja-order-dashboard .card .order-btn:active {
  background: #ffde17;
  border: 2px solid #ffde17;
}

/* DASHBOARD ORDER STYLES STOPS HERE */

/* DASHBOARD ORDER DETAILS STYLES STARTS HERE */

.oja-order-details-dashboard a {
  color: #000;
}

.oja-order-details-dashboard .card {
  max-width: 884px;
  background: #fbf8ff;
  border: 1px solid rgba(110, 68, 155, 0.3);
  border-radius: 8px;
}

.oja-order-details-dashboard .order-items .card .card-body {
  text-align: center;
}

.oja-order-details-dashboard .card .form-group .form-control {
  border: 1px solid #6e449b;
  border-radius: 8px;
  width: 440px;
}

.oja-order-details-dashboard h2 {
  font-size: 18px;
}

.oja-order-details-dashboard .order-del {
  max-width: 880px;
  text-align: end;
}

.oja-order-details-dashboard .order-details-btn {
  background-color: #d21c1c !important;
  color: #ffffff;
  border-radius: 8px;
  padding: 7px 32px;
  border: none;
  width: 114px !important;
  height: 38px;
}

/* DASHBOARD ORDER DETAILS STYLES STOPS HERE */

/* DASHBOARD CATEGORY STYLES STARTS HERE */

.oja-category-dashboard .oja-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 64px;
  column-gap: 20px;
}

.oja-category-dashboard .card {
  filter: drop-shadow(0px 1.38722px 13.8722px rgba(0, 0, 0, 0.25));
  border: 1.38722px solid #6e449b;
  border-radius: 5.54887px;
  height: 99.33px;
}

.oja-category-dashboard .card .dropdown {
  margin-left: auto !important;
}

.oja-category-dashboard .card .category-name {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #000000;
}

.oja-category-dashboard .card .category-name:hover {
  text-decoration: underline !important;
}
.oja-category-dashboard .card .category-name.special:hover {
  text-decoration: none !important;
}

.oja-category-dashboard .add-category {
  text-align: center;
}

.oja-category-dashboard .add-category img {
  margin-left: 8px;
}

.oja-category-dashboard .add-category p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #6e449b;
}

/* DASHBOARD CATEGORY STYLES STOPS HERE */

/* DASHBOARD ADD CATEGORY STYLES STARTS HERE */
.modal-content {
  width: 550px;
}
.modal-header.add-category-header {
  align-self: normal;
}
.modal-header .add-category-title {
  color: #6e449b !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}

.oja-edit-product-dashboard .add-category-image {
  height: 100px;
  width: 150px;
  cursor: pointer;
  object-fit: contain;
}

.add-category-image {
  height: 150px;
  width: 150px;
  cursor: pointer;
  object-fit: contain;
}
.view-profile-img {
  height: 150px;
  width: 150px;
  object-fit: fill;
}

.pac-container {
  z-index: 11000;
}

.modal-body.add-category-body {
  text-align: left;
  /* padding: 20px; */
}

.modal-body.add-category-body .img-con {
  text-align: center;
}

.modal-body.add-category-body .formik-input {
  border: 1px solid #6e449b !important;
  border-radius: 8px;
  width: 100%;
  height: 48px;
}
.modal-body.add-category-body.coupon .formik-input {
  border: 1px solid #6e449b !important;
  border-radius: 8px;
  width: 100%;
  height: 38px;
}
.modal-body.add-category-body .formik-input:focus {
  border-color: #6e449b !important;
  border: 1px solid #6e449b !important;
}

.modal-body.add-category-body .formik-input::placeholder {
  color: rgba(110, 68, 155, 0.3);
}

.modal-body.add-category-body .is-invalid,
.is-invalid:focus {
  border-color: red !important;
}

.modal-body.add-category-body .error {
  color: red;
  font-size: 12px;
}

.modal-body.add-category-body textarea {
  border-radius: 8px;
  border: 1px solid #6e449b !important;
}

.modal-body.add-category-body.banner .basic-multi-select .select__control {
  border: 1px solid #6e449b !important;
  border-radius: 8px !important;
}
.modal-body.add-category-body .basic-multi-select .select__control {
  border: 1px solid #6e449b !important;
  border-radius: 8px !important;
  height: 54px auto;
}

.add-category-footer {
  text-align: end !important;
}

.add-category-footer button:disabled {
  cursor: not-allowed !important;
}

.add-category-footer .oja-btn {
  padding: 7px 32px;
  width: 122px !important;
  height: 38px;
  background: #ffde17 !important;
  border-radius: 8px;
  border: none;
  color: #6e449b !important;
}

.oja-btn {
  padding: 7px 32px;
  width: 122px !important;
  height: 38px;
  background: #ffde17 !important;
  border-radius: 8px;
  border: none;
  color: #6e449b !important;
}

.modal-body.add-category-body .chrome-picker {
  width: 100% !important;
}

.modal-body.add-category-body .chrome-picker > div:nth-child(1) {
  padding-bottom: 25% !important;
}

/* DASHBOARD ADD CATEGORY STYLES STOPS HERE */

/* DASHBOARD PRODUCT STYLES STARTS HERE */

.oja-product-dashboard h1 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #6e449b;
  margin-bottom: 28px;
}

.oja-product-dashboard a p {
  color: #6e449b;
}

.oja-product-dashboard .oja-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 64px;
  column-gap: 20px;
}

.oja-product-dashboard .card {
  border: 1.38722px solid #6e449b;
  border-radius: 5.54887px;
  width: 131.56px;
}

.oja-product-dashboard .dropdown {
  position: relative;
  top: -124px;
  left: 82px;
}

.oja-product-dashboard .dropdown img:hover {
  color: #f9971e;
}

.oja-product-dashboard .card h4 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #0634d9;
  margin-top: 16.2px;
}

.oja-product-dashboard .card .price {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 6.42px;
}

.oja-product-dashboard .card .quantity {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
}

.oja-product-dashboard .add-product {
  text-align: center;
  margin-top: 40px;
}

/* DASHBOARD PRODUCT STYLES STOPS HERE */

/* DASHBOARD INVENTORY STYLES STARTS HERE */

.oja-inventory-dashboard .top {
  background: #f8f2ff;
  padding: 20px;
  margin-left: -33px;
  margin-right: -33px;
}

.oja-inventory-dashboard .top h2 {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #231f20;
}

.oja-inventory-dashboard .top .top-links {
  position: relative;
  width: 241px;
  height: 96px;
  border: 1px solid #6e449b;
  border-radius: 8px;
  text-align: center;
  color: #231f20;
}

.oja-inventory-dashboard .top .top-links:hover {
  background-color: #6e449b;
  color: #fff !important;
}

.oja-inventory-dashboard .top .top-links p {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-top: 10px;
}

.oja-inventory-dashboard .top .top-links img {
  position: absolute;
  bottom: -5px;
}

/* DASHBOARD INVENTORY STYLES STOPS HERE */

/* DASHBOARD TRANSACTION STYLES STARTS HERE */

.oja-transaction-dashboard .card {
  border: none;
  border-radius: 4px;
  background: #f4ebf9;
}

/* DASHBOARD TRANSACTION STYLES STOPS HERE */

/* DASHBOARD PAYMENT STYLES STARTS HERE */
.oja-payment-dashboard .card {
  background: #fbf8ff;
  box-shadow: 0px 1.28443px 5.77994px 0.642216px rgba(0, 0, 0, 0.25);
  width: 429px;
  margin-top: 60px;
}

.oja-payment-dashboard .card.bank-logo {
  background: #ffffff;
  box-shadow: 0px 1.28443px 5.77994px 0.642216px rgba(0, 0, 0, 0.25);
  width: 105.97px;
  margin-top: 40px;
}

.oja-payment-dashboard .box {
  background: #ffffff;
  box-shadow: 0px 1.28443px 5.77994px 0.642216px rgba(0, 0, 0, 0.25);
  width: 105.97px;
  height: 49.45px;
  text-align: center;
  margin-top: auto;
  margin-top: 40px;
}

/* DASHBOARD PAYMENT STYLES STOPS HERE */

/* DASHBOARD DATA ANALYTICS STYLES STARTS HERE */
.oja-data-analytics-dashboard .box {
  background: #fbf8ff;
  border-radius: 10px;
  width: 446px;
  height: 93px;
}

.oja-data-analytics-dashboard .oja-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 64px;
  column-gap: 20px;
}

.oja-data-analytics-dashboard img {
  margin-bottom: 15px;
}

/* DASHBOARD DATA ANALYTICS STYLES STOPS HERE */

/* DASHBOARD SETTINGS STYLES STARTS HERE */
.oja-setting-dashboard .oja-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 64px;
  column-gap: 20px;
}

.oja-setting-dashboard .box {
  background: #fbf8ff;
  border-radius: 10px;
  width: 446px;
}

.oja-setting-dashboard .title {
  color: #000;
  font-size: 20px;
}

.oja-setting-dashboard p {
  color: #000 !important;
}

/* toggle.css */

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: red;
  transition: 0.4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.4s;
}

.toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-input:checked + .toggle-slider {
  background-color: #6e449b;
}

.toggle-input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

/*=============================================
=            reset password            =
=============================================*/

.reset-form {
  width: 500px;
  /* border: 1px solid #6e449b;
  padding: 10px;
  border-radius: 12px; */
}

.reset-form  button {
  background: #6e449b;
  color: white;
}

.reset-form  button:hover {
  background: #6e449b !important;
  color: white !important;
}


/* DASHBOARD SETTINGS STYLES STOPS HERE */
