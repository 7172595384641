/* LOGIN STYLES STARTS HERE */
.oja-auth-layout-container {
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.oja-auth-layout-container .login-left {
  flex: 3;
}

.oja-auth-layout-container .login-left img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
}

.oja-auth-layout-container .login-right {
  flex: 3;
}

.oja-auth-layout-container .login-right .oja-auth-bg {
  background: #6E449B;
  height: 100vh;
  overflow-y: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.oja-auth-layout-container .login-right .card {
  position: relative;
  z-index: 100;
  margin: auto;
  max-width: 548px;
  height: 639px;
  background: #ffffff;
  border-left: 1px solid #b6b6b6;
  border-radius: 4px;
}

.oja-auth-layout-container .login-right .title {
  font-family: "Clash Display", sans-serif;
  font-size: 16px;
  line-height: 148%;
  color: #6E449B;
}

.oja-auth-layout-container .login-right .form {
  margin-bottom: 15px;
}

.oja-auth-layout-container .login-right .form .con {
  position: relative;
}
.oja-auth-layout-container .login-right .form .show {
  z-index: 9999;
  position: absolute;
  top: 30%;
  right: 10px;
  cursor: pointer;
}
.oja-auth-layout-container .login-right .formik-input {
  position: relative;
}

.oja-auth-layout-container .login-right .login-label {
  display: flex;
  margin-bottom: 15px;
  margin-top: 30px;
}

.oja-auth-layout-container .login-right .formik-input {
  display: block;
  width: 100%;
  border: 0.5px solid #b6b6b6;
  border-radius: 4px;
  height: 62px;
  padding-left: 17px;
}

.oja-auth-layout-container .login-right .formik-input:focus {
  color: #000;
  background-color: #fff;
  border-color: #f9971e;
  outline: 0;
}

.oja-auth-layout-container .login-right .formik-input::placeholder {
  font-family: "Clash Display";
  font-size: 16px;
  line-height: 148%;
  color: rgba(64, 64, 64, 0.47);
}

.is-invalid,
.is-invalid:focus {
  border-color: red !important;
}

.error {
  color: red;
  font-size: 12px !important;
}

.oja-auth-layout-container .login-right .custom-control .custom-control-label {
  font-family: "Clash Display";
  font-size: 16px;
  line-height: 19px;
  color: #6E449B;
  margin-left: 5px;
  position: relative;
  top: -7px;
}

.oja-auth-layout-container
  .login-right
  .custom-control
  .custom-control-input[type="checkbox"] {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border: 0.5px solid #b6b6b6;
  accent-color: #f9971e !important;
}

.oja-auth-layout-container .login-right .oja-forgot-pwd-a {
  font-family: "Clash Display";
  font-size: 16px;
  line-height: 148%;
  color: #f9971e !important;
}

.oja-auth-layout-container .login-right .login-btn {
  padding: 14px;
  margin-top: 60px;
  border: none;
  background: #ffde17;
  color: #ffffff;
  border-radius: 4px;
  font-family: "Clash Display";
  font-size: 18px;
  line-height: 148%;
}

/* LOGIN STYLES STOPS HERE */

/* FORGOT PASSWORD STYLES STARTS HERE */
.oja-auth-layout-container .oja-forgot-pwd-p {
  color: #000 !important;
}

.oja-auth-layout-container .oja-forgot-pwd-p .oja-forgot-pwd-a {
  color: #f9971e;
  text-decoration: underline !important;
}

/* FORGOT PASSWORD STYLES STOPS HERE */

/* SUCCESS AUTH STYLES STARTS HERE */

.oja-success-content h1 {
  font-family: "Clash Display";
  font-size: 16px;
  line-height: 148%;
  color: #3e0e54;
}

.oja-success-content p {
  font-family: "Clash Display";
  font-size: 16px;
  line-height: 19px;
  color: #404040 !important;
}

/* SUCCESS AUTH STYLES STOPS HERE */

/*  AUTH LAYOUT STYLES STARTS HERE */

.oja-auth-content h1 {
  font-family: "Clash Display";
  font-size: 16px;
  line-height: 148%;
  color: #3e0e54;
  margin-top: -10px;
}

.oja-auth-content p {
  font-family: "Clash Display";
  font-size: 16px;
  line-height: 19px;
  color: #000000 !important;
}

/*  AUTH LAYOUT STYLES STOPS HERE */
