@media (min-width: 701px) and (max-width: 1120px) {
  .sidebar-item.plain span {
    display: none !important;
  }
  .sidebar-title span {
    display: none !important;
  }
  .sidebar-item.plain img {
    display: block !important;
  }
  .oja-dashboard .oja-dashboard-sidebar {
    width: 77px !important;
  }
  .oja-dashboard .oja-dashboard-content {
    margin-left: 79px !important;
    padding: 0.5em !important;
  }
  .oja-sidebar-container {
    padding-left: 2px !important;
    padding-right: 1px !important;
  }
  .sidebar-content {
    padding-left: 4px !important;
  }
  .sidebar-item img {
    margin-right: 2px !important;
  }
  .oja-ellipsis {
    max-width: 50px !important;
  }
  .order th {
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .oja-nav-dashboard .oja-nav-logo {
    margin-left: -25px !important;
  }
  .oja-nav-dashboard .oja-nav-form {
    margin-left: -200px !important;
  }
}
